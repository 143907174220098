import React from 'react'

const Solutions = () => {
  return (
    <div>
      
    </div>
  )
}

export default Solutions
